import bvUtils from '@bv/utils';
import { Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoBack } from '@public-auth/components/GoBack';
import { routeAnimationVariants } from '@public-auth/router';
import { motion } from 'framer-motion';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { unitInSessionAtom, UserData, userDataAtom, userViewTypeAtom } from '../../atom/auth.atom';

const formSchema = z.object({
  role: z.string().min(1, { message: 'Selecione um perfil' }),
  unit: z.string().min(1, { message: 'Selecione uma unidade' }),
});

export default function ProfileSelectorPage() {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const userData = useAtomValue<UserData>(userDataAtom);
  const setUserViewType = useSetAtom(userViewTypeAtom);
  const setUnitInSession = useSetAtom(unitInSessionAtom);
  const allUserUnits = bvUtils.session.getAllUserUnitsUtil();
  const unitsAsAdmin = bvUtils.session.getUnitsAsAdminForComboUtil();
  const unitsAsDoctor = bvUtils.session.getUnitsAsDoctorForComboUtil();
  const unitsAsEmployee = bvUtils.session.getUnitsAsEmployeeForComboUtil();
  const roleOptions = bvUtils.session.getRolesForComboUtil(userData?.loggedUser?.accessToken);

  const roleWatch = methods.watch('role');

  const unitOptions = useMemo(() => {
    if (roleWatch === 'admin') return unitsAsAdmin;
    if (roleWatch === 'doctor') return unitsAsDoctor;
    if (roleWatch === 'employee') return unitsAsEmployee;
    return [];
  }, [roleWatch, unitsAsAdmin, unitsAsDoctor, unitsAsEmployee]);

  // console.log(unitOptions);

  function setupSessionHandler(data: z.infer<typeof formSchema>) {
    const selectedUnit = allUserUnits.find((unit) => unit._id === data.unit);

    const pureUser = { ...userData };
    delete pureUser.loggedUser;

    setUserViewType(data.role);
    setUnitInSession(selectedUnit);
    // console.log(userData.loggedUser.accessToken);
    // console.log({ pureUser });
    // console.log({ selectedUnit });
    // console.log(data.role);

    bvUtils.cookies.setAccessToken(userData.loggedUser.accessToken);
    bvUtils.cookies.setCurrentUser(pureUser);

    window.location.href = '/dashboard';
  }

  useEffect(() => {
    if (!userData?.loggedUser?.accessToken) navigate('/login', { replace: true });
  }, []);

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <div className="flex items-start gap-3">
        <GoBack />
        <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">
          <span className="text-brand-primary">{userData.name}</span>, estamos quase lá!
        </h2>
      </div>
      <span className="text-typography-secondary text-sm">Com qual perfil você deseja navegar?</span>

      <div className="mt-8 max-w-[405px]">
        <Form ctx={methods} onSubmit={setupSessionHandler}>
          <FormInput
            type={EInput.SELECT}
            name="role"
            label="Perfil"
            placeholder="Selecione um perfil"
            options={roleOptions}
            popoverClassName="w-[405px]"
          />
          <FormInput
            type={EInput.SELECT_SEARCH}
            name="unit"
            label="Unidade"
            placeholder="Selecione uma unidade"
            options={unitOptions}
            disabled={!roleWatch}
            popoverClassName="w-[405px]"
          />
          <Button size={'lg'} type="submit" className="mt-6 w-full">
            Entrar
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
