import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useEffect, useState } from 'react';
import bvStyleguide from '@bv/styleguide';
import bvUtils from '@bv/utils';

//trigger pipeline4

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // logout provisório
    try {
      window.localStorage.clear();
      bvUtils.cookies.clearAuthCookies();
      console.log('localStorage e cookies limpos');
    } catch (error) {
      console.log('Erro ao limpar localStorage e cookies', error);
    }

    const token = localStorage.getItem('token');

    if (!token) setIsReady(true);
  }, []);

  if (!isReady) return <bvStyleguide.PageLoader />;

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
        <RouterProvider router={router} />
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  );
}
