import bvUtils from '@bv/utils';
import { Alert, AlertDescription, AlertTitle, Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoBack } from '@public-auth/components/GoBack';
import { POST_FORGOT_PASSWORD } from '@public-auth/constants/queries';
import { routeAnimationVariants } from '@public-auth/router';
import { postForgotPasswordService } from '@public-auth/services/auth.service';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { MailIcon, SendIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  email: z.string().email(),
});

export default function ForgotPasswordPage() {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false);
  const emailWatch = methods.watch('email');

  const { mutate: forgotPasswordHandler, isPending } = useMutation({
    mutationKey: [POST_FORGOT_PASSWORD],
    mutationFn: async (data: z.infer<typeof formSchema>) => postForgotPasswordService(data.email),
    onSuccess: (data) => {
      setForgotPasswordEmailSent(true);
      bvUtils.toastHandler({ type: 'success', message: 'E-mail de recupreacão de senha enviado com sucesso!' });
    },
  });

  if (forgotPasswordEmailSent) {
    return (
      <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
        <div className="flex items-start gap-3">
          <GoBack />
          <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Redefinir senha</h2>
        </div>
        <span className="text-typography-secondary text-sm">
          Enviamos um e-mail de redefinição de senha. Abra sua caixa de e-mail para finalizar.
        </span>

        <div className="mt-8 max-w-[405px]">
          <Alert variant="info">
            <SendIcon className="h-4 w-4" />
            <AlertTitle>Email enviado para:</AlertTitle>
            <AlertDescription>
              <strong>{emailWatch}</strong>, siga as instruções no e-mail para redefinir sua senha.
            </AlertDescription>
          </Alert>
          <Button size={'lg'} className="mt-8 w-full" variant="outline" onClick={() => navigate('/')}>
            Voltar
          </Button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <div className="flex items-start gap-3">
        <GoBack />
        <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Redefinir senha</h2>
      </div>
      <span className="text-typography-secondary text-sm">Insira seu e-mail cadastrado no Benefício Visão para redefinir sua senha.</span>

      <div className="mt-8 max-w-[405px]">
        <Form ctx={methods} onSubmit={forgotPasswordHandler}>
          <FormInput
            inputIcon={<MailIcon size={20} className="text-brand-primary" />}
            name="email"
            label="E-mail cadastrado"
            placeholder="Digite seu e-mail"
            type={EInput.EMAIL}
          />
          <Button size={'lg'} type="submit" className="mt-6 w-full" loading={isPending}>
            Avançar
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
