import ForgotPasswordPage from '@public-auth/pages/ForgotPassword';
import LoginPage from '@public-auth/pages/Login';
import ProfileSelectorPage from '@public-auth/pages/ProfileSelector';
import ResetPasswordPage from '@public-auth/pages/ResetPassword';
import { AnimatePresence } from 'framer-motion';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Layout } from '../components/Layout';

export const routeAnimationVariants = {
  initial: {
    opacity: 0,
    y: -24,
  },
  final: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 16,
  },
};

export const router = createBrowserRouter(
  [
    {
      element: (
        <Layout>
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
        </Layout>
      ),
      children: [
        {
          path: '/',
          element: <LoginPage />,
        },
        {
          path: '/esqueci-senha',
          element: <ForgotPasswordPage />,
        },
        {
          path: '/redefinir-senha',
          element: <ResetPasswordPage />,
        },
        {
          path: '/selecionar-perfil',
          element: <ProfileSelectorPage />,
        },
      ],
    },
  ],
  { basename: '/login' },
);
