import bvUtils from '@bv/utils';
import { Button, EInput, Form, FormInput } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserData, userDataAtom } from '../../../../atom/auth.atom';
import { routeAnimationVariants } from '@public-auth/router';
import { getPreLoginService } from '@public-auth/services/auth.service';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { MailIcon } from 'lucide-react';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  email: z.string().email({ message: 'E-mail inválido.' }),
});

export function EmailForm() {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);

  async function getPreLogin(data: z.infer<typeof formSchema>) {
    setLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha('PRE_LOGIN');

      const response = await getPreLoginService({ email: data.email, recaptchaToken });
      setUserData({ ...response, email: data.email, loggedUser: {} } as UserData);
    } catch (error) {
      console.log('error', error);
      // bvUtils.toastHandler({ type: 'error', message: error.message });
      setLoading(false);
    }
  }

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Realize seu login</h2>
      <span className="text-typography-secondary text-sm">Faça seu login com e-mail e senha para acessar e usufruir do Benefício Visão.</span>

      <div className="mt-8 max-w-[405px]">
        <Form ctx={methods} onSubmit={getPreLogin}>
          <FormInput
            inputIcon={<MailIcon className="text-brand-primary" size={20} />}
            name="email"
            label="E-mail cadastrado"
            placeholder="Digite seu e-mail"
            type={EInput.EMAIL}
          />
          <Button size={'lg'} type="submit" className="mt-6 w-full" loading={loading}>
            Continuar
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
