import BVStyleguide from '@bv/styleguide';
import bvUtils from '@bv/utils';
import { Alert, AlertDescription, Button, cn, EInput, Form, FormInput, IconButton } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { POST_RESET_PASSWORD } from '@public-auth/constants/queries';
import { routeAnimationVariants } from '@public-auth/router';
import { PostResetPasswordDto, postResetPasswordService } from '../../services/auth.service';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { CheckIcon, EyeIcon, EyeOffIcon, XIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  password: z
    .string()
    .min(8, { message: 'A senha deve ter no mínimo 8 caracteres.' })
    .regex(/\d/, { message: 'A senha deve conter pelo menos 1 número.' })
    .regex(/[@!$\-_%&*#]/, { message: 'A senha deve conter pelo menos 1 caractere especial (@!$-_%&*#).' }),
  confirmPassword: z.string().min(1, { message: 'Você precisa digitar a confirmação de senha.' }),
});

export default function ResetPasswordPage() {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [seePassword, setSeePassword] = useState(false);
  const [isPasswordMatchWithConfirmPassword, setIsPasswordMatchWithConfirmPassword] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);
  const [passwordHasMinLength, setPasswordHasMinLength] = useState(false);

  const canResetPassword = useMemo(() => {
    return passwordHasNumber && passwordHasSpecialCharacter && passwordHasMinLength && isPasswordMatchWithConfirmPassword;
  }, [passwordHasNumber, passwordHasSpecialCharacter, passwordHasMinLength, isPasswordMatchWithConfirmPassword]);

  const passwordWatch = methods.watch('password');
  const confirmPasswordWatch = methods.watch('confirmPassword');

  useEffect(() => {
    if (passwordWatch) {
      setPasswordHasNumber(/\d/.test(passwordWatch));
      setPasswordHasSpecialCharacter(/[@!$\-_%&*#]/.test(passwordWatch));
      setPasswordHasMinLength(passwordWatch.length >= 8);
    }
    if (confirmPasswordWatch) {
      setIsPasswordMatchWithConfirmPassword(passwordWatch === confirmPasswordWatch);
    }
  }, [passwordWatch, confirmPasswordWatch]);

  const { mutate: signIn, isPending } = useMutation({
    mutationKey: [POST_RESET_PASSWORD],
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      const objToSend: PostResetPasswordDto = {
        password: data.password,
        repeatPassword: data.confirmPassword,
        token: searchParams.get('token'),
      };

      return postResetPasswordService(objToSend);
    },
    onSuccess: (data) => {
      bvUtils.toastHandler({ type: 'success', message: 'Senha alterada com sucesso!' });
      navigate('/', { replace: true });
    },
  });

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">Redefinir senha</h2>
      <span className="text-typography-secondary text-sm">Digite sua nova senha para acessar o Benefício Visão.</span>

      <div className="mt-8 max-w-[405px]">
        <Form ctx={methods} onSubmit={signIn}>
          <FormInput
            name="password"
            label="Senha"
            placeholder="Digite sua senha"
            type={seePassword ? EInput.TEXT : EInput.PASSWORD}
            inputIconOrientation="right"
            inputIcon={
              <IconButton onClick={() => setSeePassword((prev) => !prev)}>
                {seePassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
              </IconButton>
            }
          />

          <FormInput
            name="confirmPassword"
            label="Confirmação de senha"
            placeholder="Digite sua senha"
            type={seePassword ? EInput.TEXT : EInput.PASSWORD}
            inputIconOrientation="right"
            inputIcon={
              <IconButton onClick={() => setSeePassword((prev) => !prev)}>
                {seePassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
              </IconButton>
            }
          />

          <Alert variant="info" className="mt-2">
            <AlertDescription>
              <ul className="space-y-2">
                <li className={cn('text-typography-secondary flex items-start gap-2 text-xs', passwordHasMinLength && 'text-success-dark')}>
                  {passwordHasMinLength ? <CheckIcon size={16} /> : <XIcon size={16} />}{' '}
                  <span className="flex-1">
                    A senha deve conter no mínimo <strong>8 digitos</strong>
                  </span>
                </li>
                <li className={cn('text-typography-secondary flex items-start gap-2 text-xs', passwordHasNumber && 'text-success-dark')}>
                  {passwordHasNumber ? <CheckIcon size={16} /> : <XIcon size={16} />}{' '}
                  <span className="flex-1">
                    A senha deve conter pelo menos <strong>1 número</strong>
                  </span>
                </li>
                <li className={cn('text-typography-secondary flex items-start gap-2 text-xs', passwordHasSpecialCharacter && 'text-success-dark')}>
                  {passwordHasSpecialCharacter ? <CheckIcon size={16} /> : <XIcon size={16} />}
                  <span className="flex-1">
                    A senha deve conter pelo menos <strong>um caractere especial (@!$-_%&*#)</strong>
                  </span>
                </li>
                <li
                  className={cn(
                    'text-typography-secondary flex items-start gap-2 text-xs',
                    isPasswordMatchWithConfirmPassword && 'text-success-dark',
                  )}>
                  {isPasswordMatchWithConfirmPassword ? <CheckIcon size={16} /> : <XIcon size={16} />}
                  <span className="flex-1">As senhas devem ser iguais</span>
                </li>
              </ul>
            </AlertDescription>
          </Alert>

          <Button type="submit" size="lg" className="mt-6 w-full" loading={isPending} disabled={!canResetPassword}>
            Redefinir senha
          </Button>
        </Form>
      </div>
    </motion.div>
  );
}
