import bvUtils from '@bv/utils';
import { Button } from '@eyecarehealth/ds-aquilae-react';
import { GoBack } from '@public-auth/components/GoBack';
import { POST_SIGN_IN } from '@public-auth/constants/queries';
import { routeAnimationVariants } from '@public-auth/router';
import { postSignInService, signInWithAzureService } from '@public-auth/services/auth.service';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtom, useSetAtom } from 'jotai';
import { jwtDecode } from 'jwt-decode';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { User, UserFromToken } from '../../../../../../../types/v2/user';
import { unitInSessionAtom, UserData, userDataAtom, userViewTypeAtom } from '../../../../atom/auth.atom';

export function SSOForm() {
  const navigate = useNavigate();
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const setUserViewType = useSetAtom(userViewTypeAtom);
  const setUnitInSession = useSetAtom(unitInSessionAtom);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate: signInWithSSO, isPending } = useMutation({
    mutationKey: [POST_SIGN_IN],
    mutationFn: async () => {
      let account = null;

      if (userData.companies.sso.cloudPlatform === 'AZURE') {
        account = await signInWithAzureService(userData as unknown as User, userData.companies.sso);
      }

      const recaptchaToken = await executeRecaptcha('LOGIN');

      console.log({ account });

      return postSignInService({
        username: userData.email,
        password: null,
        ssoToken: account?.accessToken,
        recaptchaToken,
      });
    },
    onSuccess: (data) => {
      const decodedToken: UserFromToken = jwtDecode(data.accessToken);
      bvUtils.session.setUnitAsEmployeeUtil(data.unitAsEmployee);
      bvUtils.session.setUnitsAsAdminUtil(data.unitsAsAdmin);
      bvUtils.session.setUnitsAsDoctorUtil(data.unitsAsDoctor);
      // console.log(JSON.stringify(decodedToken));

      try {
        if (bvUtils.session.userHasMoreThanOneUnitUtil()) {
          setUserData({ ...userData, loggedUser: data });
          navigate('/selecionar-perfil');
        } else {
          setUserViewType(decodedToken.roles[0]);
          const selectedUnit = bvUtils.session.getUserUniqueUnitUtil();
          console.log('Unidade Selecionada:', JSON.stringify(selectedUnit));
          setUnitInSession(selectedUnit);
          bvUtils.cookies.setAccessToken(data.accessToken);
          bvUtils.cookies.setCurrentUser(data.user);
          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.log({ error });
      }
    },
  });

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <div className="flex items-start gap-3">
        <GoBack action={() => setUserData({} as UserData)} />
        <h2 className="text-typography mb-2 flex-1 text-3xl font-[500]">
          Bem vindo(a) de volta,
          <br /> <span className="text-brand-primary">{userData.name}</span>
        </h2>
      </div>
      <span className="text-typography-secondary text-sm">
        Sua autenticação no Benefício Visão é feita por meio da {userData?.companies?.sso?.cloudPlatform}, por favor faça login com a sua conta na
        plataforma.
      </span>
      <div className="mt-8 max-w-[405px]">
        <Button size="lg" className="mt-6 w-full" loading={isPending} onClick={() => signInWithSSO()}>
          Entrar com {userData?.companies?.sso?.cloudPlatform}
        </Button>
      </div>
    </motion.div>
  );
}
