import { IconButton } from '@eyecarehealth/ds-aquilae-react';
import { UserData, userDataAtom } from '../../atom/auth.atom';
import { useAtomValue } from 'jotai';
import { ArrowLeftIcon, BuildingIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

export function GoBack({ link, action }: { link?: string; action?: () => void }) {
  const navigate = useNavigate();
  const userData = useAtomValue<UserData>(userDataAtom);

  if (!userData) return null;

  if (action) {
    return (
      <IconButton variant="default" onClick={action} className="text-typography !rounded-1 flex items-center gap-4 text-sm font-medium">
        <ArrowLeftIcon size={20} className="text-brand-primary" />
      </IconButton>
    );
  }

  return (
    <IconButton
      variant="default"
      onClick={() => (link ? navigate(link) : navigate(-1))}
      className="text-typography !rounded-1 flex items-center gap-4 text-sm font-medium">
      <ArrowLeftIcon size={20} className="text-brand-primary" />
    </IconButton>
  );
}
