import bvUtils from '@bv/utils';
import { Button, EInput, Form, FormInput, IconButton } from '@eyecarehealth/ds-aquilae-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoBack } from '@public-auth/components/GoBack';
import { POST_SIGN_IN } from '@public-auth/constants/queries';
import { routeAnimationVariants } from '@public-auth/router';
import { postSignInService } from '@public-auth/services/auth.service';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtom, useSetAtom } from 'jotai';
import { jwtDecode } from 'jwt-decode';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { UserFromToken } from '../../../../../../../types/v2/user';
import { unitInSessionAtom, UserData, userDataAtom, userViewTypeAtom } from '../../../../atom/auth.atom';

const formSchema = z.object({
  password: z.string().min(1, { message: 'Você precisa digitar sua senha.' }),
});

export function PasswordForm() {
  const methods = useForm({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const setUserViewType = useSetAtom(userViewTypeAtom);
  const setUnitInSession = useSetAtom(unitInSessionAtom);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [seePassword, setSeePassword] = useState(false);

  const { mutate: signIn, isPending } = useMutation({
    mutationKey: [POST_SIGN_IN],
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      const recaptchaToken = await executeRecaptcha('LOGIN');

      return postSignInService({
        username: userData.email,
        password: data.password,
        recaptchaToken,
      });
    },
    onSuccess: (data) => {
      const decodedToken: UserFromToken = jwtDecode(data.accessToken);
      bvUtils.session.setUnitAsEmployeeUtil(data.unitAsEmployee);
      bvUtils.session.setUnitsAsAdminUtil(data.unitsAsAdmin);
      bvUtils.session.setUnitsAsDoctorUtil(data.unitsAsDoctor);
      // console.log(JSON.stringify(decodedToken));

      try {
        if (bvUtils.session.userHasMoreThanOneUnitUtil()) {
          setUserData({ ...userData, loggedUser: data });
          navigate('/selecionar-perfil');
        } else {
          setUserViewType(decodedToken.roles[0]);
          const selectedUnit = bvUtils.session.getUserUniqueUnitUtil();
          console.log('Unidade Selecionada:', JSON.stringify(selectedUnit));
          setUnitInSession(selectedUnit);
          bvUtils.cookies.setAccessToken(data.accessToken);
          bvUtils.cookies.setCurrentUser(data.user);
          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.log({ error });
      }
    },
  });

  return (
    <motion.div animate="final" initial="initial" variants={routeAnimationVariants}>
      <div className="flex items-start gap-3">
        <GoBack action={() => setUserData({} as UserData)} />
        <h2 className="text-typography mb-2 max-w-[420px] flex-1 text-3xl font-[500]">
          Bem vindo(a) de volta, <span className="text-brand-primary">{userData.name}</span>
        </h2>
      </div>
      <div className="mt-8 max-w-[405px]">
        <Form ctx={methods} onSubmit={signIn}>
          <FormInput
            name="password"
            label="Senha"
            placeholder="Digite sua senha"
            type={seePassword ? EInput.TEXT : EInput.PASSWORD}
            inputIconOrientation="right"
            inputIcon={
              <IconButton onClick={() => setSeePassword((prev) => !prev)}>
                {seePassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
              </IconButton>
            }
          />

          <Button type="submit" size="lg" className="mt-6 w-full" loading={isPending}>
            Entrar no Benefício Visão
          </Button>
        </Form>
        <Button
          className="mt-6 w-full"
          variant="link"
          disabled={isPending}
          onClick={() => {
            setUserData({} as UserData);
            navigate('/esqueci-senha');
          }}>
          Esqueci minha senha
        </Button>
      </div>
    </motion.div>
  );
}
