import { PublicClientApplication } from '@azure/msal-browser';
import { SSO } from '../../../../types/v2/company';
import { User, UserUnit } from '../../../../types/v2/user';
import { api } from './api';

export type PostSignInDto = {
  username: string;
  password?: string;
  ssoToken?: string;
  recaptchaToken?: string;
};

export type PostSignInResponse = {
  user: User;
  accessToken: string;
  unitAsEmployee?: UserUnit;
  unitsAsAdmin?: UserUnit[];
  unitsAsDoctor?: UserUnit[];
};

export type PostResetPasswordDto = {
  token: string;
  password: string;
  repeatPassword: string;
};

export type PostForgotPasswordResponse = {};

export type PostResetPasswordResponse = {};

export type GetPreLoginQuery = {
  email: string;
  recaptchaToken: string;
};

export type GetPreLoginResponse = {
  _id: string;
  name: string;
  isSSOEnabled: boolean;
  companies: {
    address: {
      location: {
        type: string;
        coordinates: Array<number>;
      };
      city: string;
      complement: string;
      country: string;
      neighbourhood: string;
      number: string;
      state: string;
      street: string;
      zipCode: string;
    };
    sso: {
      clientId: string;
      cloudPlatform: string;
      authority: string;
      secretKey: string;
      tenantId: string;
    };
    _id: string;
    rootCompanyId: any;
    oldId: string;
    partnerCompanyId: any;
    businessIdentifier: string;
    countryCode: string;
    oldType: string;
    corporateName: string;
    name: string;
    status: string;
    isSSOEnabled: boolean;
    brand: string;
    createdAt: string;
    updatedAt: string;
    inviteValidationType: string;
    inviteCustomLabel: string;
    rating: number;
    __v: number;
  };
  jobPosition: string;
  cpf: string;
  root: boolean;
  birthdate: string;
  gender: string;
  cellphone: string;
  acceptedTerms: boolean;
  tokenResetPassword?: string;
};

function getPreLoginService(query: GetPreLoginQuery): Promise<GetPreLoginResponse> {
  return api.get(`/v2/auth/pre-login`, { params: query });
}

function postSignInService(body: PostSignInDto): Promise<PostSignInResponse> {
  return api.post(`/v2/auth/login`, body);
}

function postForgotPasswordService(email: string): Promise<PostForgotPasswordResponse> {
  return api.post(`/v2/auth/password/request-reset`, { email });
}

function postResetPasswordService(body: PostResetPasswordDto): Promise<PostResetPasswordResponse> {
  return api.post(`/v2/auth/password/redefine`, body);
}

async function signInWithAzureService(user: User, userSSOConfig: SSO) {
  const ENV = process.env.NODE_ENV;
  let URL = '';

  if (ENV === 'development') {
    URL = `https://dev.beneficiovisao.com.br/login`;
  }
  if (ENV === 'production') {
    URL = `https://app.beneficiovisao.com.br/login`;
  }
  if (ENV === 'stage') {
    URL = `https://stage.beneficiovisao.com.br/login`;
  }
  if (ENV === 'development') {
    URL = `http://localhost:${9000}/login`;
  }
  if (!URL) URL = `https://app.beneficiovisao.com.br/login`;

  const msalConfig = {
    auth: {
      clientId: userSSOConfig.clientId,
      authority: 'https://login.microsoftonline.com/organizations',
      redirectUri: `${URL}`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          console.debug(message);
        },
      },
    },
  };

  const loginRequest = {
    scopes: ['User.Read'],
    loginHint: user.email,
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    try {
      const account = await msalInstance.loginPopup(loginRequest);
      // TODO login with only username | return accessToken and user
      return account;
    } catch (error) {
      console.log(error);
      throw {
        message: error?.message,
        closedModal: true,
      };
    }
  }

  return null;
}

export { getPreLoginService, postSignInService, postForgotPasswordService, postResetPasswordService, signInWithAzureService };
